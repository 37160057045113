import { useEffect, useState } from "react";
import { List, Layout, Button, Row, Input, Descriptions } from "antd";
import { PlusOutlined, FileDoneOutlined } from "@ant-design/icons";
import { NavLink, useHistory } from "react-router-dom";
import { DataStore } from "@aws-amplify/datastore";
import Header from "../../components/Header";
import { SafeCard } from "../../models";
import { Cache } from "aws-amplify";
import moment from "moment";
import { Hub } from "@aws-amplify/core";

const { Content, Footer } = Layout;
const { Search } = Input;

function HomePage() {
  const [checklists, setChecklists] = useState([]);
  const [filteredChecklist, setFilteredChecklist] = useState([]);
  let history = useHistory();

  useEffect(() => {
    fetchChecklists();
    // Create listener that will stop observing the model once the sync process is done
    const removeListener = Hub.listen("datastore", async (capsule) => {
      const {
        payload: { event, data },
      } = capsule;

      console.log("DataStore event", event, data);

      if (event === "ready") {
        fetchChecklists();
      }
    });

    // Start the DataStore, this kicks-off the sync process.
    DataStore.start();

    return () => {
      removeListener();
    };
  }, []);

  async function fetchChecklists() {
    try {
      const checklists = await DataStore.query(SafeCard);
      setChecklists(checklists);
      setFilteredChecklist(checklists);
    } catch (err) {
      console.log(err);
    }
  }

  const onSearch = (value) => {
    setFilteredChecklist(
      checklists.filter((checklist) =>
        Object.keys(checklist).some(
          (k) =>
            typeof checklist[k] === "string" &&
            checklist[k].toLowerCase().includes(value.toLowerCase())
        )
      )
    );
  };

  return (
    <Layout className="layout">
      <Header history={history} />
      <Content style={{ padding: "0 20px" }}>
        <Row style={{ margin: 20 }}>
          <Descriptions>
            <Descriptions.Item label="User">
              {Cache.getItem("name")}
            </Descriptions.Item>
            <Descriptions.Item label="Business Unit">
              {Cache.getItem("department")}
            </Descriptions.Item>
            <Descriptions.Item label="Date">
              {moment().format("LL").toString()}
            </Descriptions.Item>
          </Descriptions>
        </Row>
        <Row justify={"center"}>
          <NavLink to="/new-checklist">
            <Button
              type="primary"
              icon={<PlusOutlined />}
              style={{ margin: 20 }}
            >
              New Observation
            </Button>
          </NavLink>
        </Row>
        <Row>
          <Search
            placeholder="Search Safe Card"
            allowClear
            onSearch={onSearch}
          />
        </Row>
        <List
          itemLayout="horizontal"
          dataSource={filteredChecklist}
          renderItem={(item) => (
            <NavLink
              to={{
                pathname: `/details/${item.id}`,
              }}
            >
              <List.Item style={{ textAlign: "left" }}>
                <List.Item.Meta
                  avatar={<FileDoneOutlined />}
                  title={item.date}
                  description={item.location}
                />
              </List.Item>
            </NavLink>
          )}
        />
      </Content>
      <Footer />
    </Layout>
  );
}

export default HomePage;
