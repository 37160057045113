import { useState, useEffect } from "react";
import { Storage } from "aws-amplify";
import { readRemoteFile } from "react-papaparse";
import { Select, Row, Col, Typography } from "antd";

const { Option } = Select;
const { Text } = Typography;

function UserListComponent(props) {
  const { setObservedBy, setDepartment } = props;
  const [users, setUsers] = useState([]);

  useEffect(() => {
    getPresignedUrl("ad_users/AD Report Users TES.csv");
  }, []);

  async function getPresignedUrl(fileUrl) {
    const url = await Storage.get(fileUrl);
    readRemoteFile(url, {
      download: true,
      complete: (results) => {
        // results.data has user details in an array, slice to remove the headers from csv
        setUsers(results.data.slice(1));
      },
    });
  }

  function onChange(value) {
    setObservedBy(`${users[value][0]} ${users[value][1]}`);
    setDepartment(users[value][2]);
  }

  return (
    <div>
      <Row gutter={[16, 16]} style={{ textAlign: "start", marginBottom: 10 }}>
        <Col span={12}>
          <Text>Observed By</Text>
        </Col>
        <Col span={12}>
          <Select
            showSearch
            onChange={onChange}
            style={{ width: 140 }}
            placeholder="Select a person"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {users.map((user, i) => (
              <Option key={i} value={i}>
                {`${user[0]} ${user[1]}`}
              </Option>
            ))}
          </Select>
        </Col>
      </Row>
    </div>
  );
}

export default UserListComponent;
