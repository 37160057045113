import logo from "../../assets/images/tucker_logo.png";
import { Layout } from "antd";
const { Header } = Layout;

function AppPageHeader() {
  return (
    <Header>
      <div style={{ flex: 1, textAlign: "center" }}>
        <img
          src={logo}
          style={{
            width: "100px",
            height: "30px",
          }}
        />
      </div>
    </Header>
  );
}

export default AppPageHeader;
