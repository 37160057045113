import { useState } from "react";
import {
  Steps,
  Button,
  message,
  Layout,
  Checkbox,
  Typography,
  Form,
} from "antd";
import SubmissionPage from "./SubmissionPage";
import "./ObservationPage.less";
import { DataStore } from "@aws-amplify/datastore";
import moment from "moment";
import { useHistory } from "react-router-dom";
import Header from "../../components/Header";
import { SafeCard } from "../../models";
import { Cache } from "aws-amplify";

const { Content } = Layout;
const { Text } = Typography;

const { Step } = Steps;

const steps = [
  {
    title: "Reactions of People",
    options: [
      {
        label: "Adjusting Personal Protective Equipment",
        value: "Adjusting Personal Protective Equipment",
      },
      { label: "Changing Position", value: "Changing Position" },
      { label: "Rearranging Job", value: "Rearranging Job" },
      { label: "Attaching Grounds", value: "Attaching Grounds" },
      { label: "Performing Lockouts", value: "Performing Lockouts" },
    ],
  },
  {
    title: "Personal Protective Equipment",
    options: [
      { label: "Head", value: "Head" },
      { label: "Eyes and Face", value: "Eyes and Face" },
      { label: "Ears", value: "Ears" },
      { label: "Respiratory System", value: "Respiratory System" },
      { label: "Arms and Hands", value: "Arms and Hands" },
      { label: "Trunk", value: "Trunk" },
      { label: "Legs and Feet", value: "Legs and Feet" },
    ],
  },
  {
    title: "Positions Of People (Injury Causes)",
    options: [
      { label: "Striking against Objects", value: "Striking against Objects" },
      { label: "Struck by Objects", value: "Struck by Objects" },
      {
        label: "Caught In, On, or Between Objects",
        value: "Caught In, On, or Between Objects",
      },
      { label: "Falling", value: "Falling" },
      {
        label: "Contacting Temperature Extremes",
        value: "Contacting Temperature Extremes",
      },
      { label: "Contacting Electricity", value: "Contacting Electricity" },
      {
        label: "Inhaling a Hazardous Substance",
        value: "Inhaling a Hazardous Substance",
      },
      {
        label: "Absorbing a Hazardous Substance",
        value: "Absorbing a Hazardous Substance",
      },
      {
        label: "Swallowing a Hazardous Substance",
        value: "Swallowing a Hazardous Substance",
      },
      { label: "Overexertion", value: "Overexertion" },
      { label: "Repetitive Motions", value: "Repetitive Motions" },
      {
        label: "Awkward Positions/ Static Postures",
        value: "Awkward Positions/ Static Postures",
      },
    ],
  },
  {
    title: "Tools and Equipment",
    options: [
      { label: "Wrong for the Job", value: "Wrong for the Job" },
      { label: "Used Incorrectly", value: "Used Incorrectly" },
      { label: "In Unsafe Condition", value: "In Unsafe Condition" },
    ],
  },
  {
    title: "Safe Work Procedures",
    options: [
      { label: "Inadequate", value: "Inadequate" },
      { label: "Not Known/ Understood", value: "Not Known/ Understood" },
      { label: "Not Followed", value: "Not Followed" },
      {
        label: "Orderliness Standards Inadequate",
        value: "Orderliness Standards Inadequate",
      },
      {
        label: "Orderliness Standards Not Known/ Understood",
        value: "Orderliness Standards Not Known/ Understood",
      },
      {
        label: "Orderliness Standards Not Followed",
        value: "Orderliness Standards Not Followed",
      },
    ],
  },
  {
    title: "Safety Observation Report",
  },
];

const ObservationPage = () => {
  const [current, setCurrent] = useState(0);
  const [status, setStatus] = useState([
    "process",
    "wait",
    "wait",
    "wait",
    "wait",
    "wait",
  ]);
  const [furtherAction, setFurtherAction] = useState(false);
  const [location, setLocation] = useState("Chaguaramas Facility");
  const [otherLocation, setOtherLocation] = useState("");
  const [department, setDepartment] = useState(Cache.getItem("department"));
  const [safeActs, setSafeActs] = useState("");
  const [unsafeActs, setUnsafeActs] = useState("");
  const [checked, setChecked] = useState([[], [], [], [], [], []]);
  const [images, setImages] = useState([]);
  const [observedBy, setObservedBy] = useState(Cache.getItem("name"));
  let history = useHistory();

  function onChange(values) {
    checked[current] = values;
    setChecked(checked);
  }

  const next = () => {
    checked[current] === undefined || checked[current].length === 0
      ? (status[current] = "finish")
      : (status[current] = "error");
    setCurrent(current + 1);
    status[current + 1] = "process";
    setStatus(status);
  };

  const prev = () => {
    status[current] = "wait";
    setCurrent(current - 1);
    status[current - 1] = "process";
    setStatus(status);
  };

  async function addChecklist() {
    try {
      await DataStore.save(
        new SafeCard({
          observedBy: observedBy,
          email: Cache.getItem("email"),
          location: location === "Other" ? otherLocation : location,
          department: department,
          date: moment().format("LL").toString(),
          reactions: checked[0],
          equipment: checked[1],
          positions: checked[2],
          tools: checked[3],
          procedures: checked[4],
          safeActs: safeActs,
          unsafeActs: unsafeActs,
          furtherAction: furtherAction,
          images: images,
        })
      );
      message.success("Processing complete!");
      history.goBack();
    } catch (err) {
      message.error("error creating checklist:", err);
      console.log("error creating checklist:", err);
    }
  }
  const onFinish = (values) => {
    addChecklist();
  };

  const onFinishFailed = (errorInfo) => {
    message.error("Please complete the form");
  };

  return (
    <>
      <Layout className="layout">
        <Header history={history} />
        <Content style={{ padding: "15px" }}>
          <Form onFinish={onFinish} onFinishFailed={onFinishFailed}>
            <Steps
              direction="vertical"
              size="small"
              current={current}
              style={{ padding: "20px" }}
            >
              {steps.map((item, index) => (
                <Step
                  key={item.title}
                  title={item.title}
                  status={status[index]}
                />
              ))}
            </Steps>
            <div className="steps-content">
              {current === steps.length - 1 ? (
                <SubmissionPage
                  furtherAction={furtherAction}
                  setFurtherAction={setFurtherAction}
                  location={location}
                  setLocation={setLocation}
                  department={department}
                  setDepartment={setDepartment}
                  safeActs={safeActs}
                  setSafeActs={setSafeActs}
                  unsafeActs={unsafeActs}
                  setUnsafeActs={setUnsafeActs}
                  setOtherLocation={setOtherLocation}
                  status={status}
                  images={images}
                  setImages={setImages}
                  setObservedBy={setObservedBy}
                />
              ) : (
                <Checkbox.Group
                  options={steps[current].options}
                  onChange={onChange}
                  defaultValue={checked[current]}
                  className="custom-check"
                />
              )}
            </div>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Text type="secondary">HSE-004 Rev 0 Dated 18-Feb-2008</Text>
            </div>
            <div className="steps-action">
              {current < steps.length - 1 && (
                <Button type="primary" onClick={() => next()}>
                  Next
                </Button>
              )}
              {current === steps.length - 1 && (
                <Button type="primary" htmlType="submit">
                  Done
                </Button>
              )}
              {current > 0 && (
                <Button style={{ margin: "0 8px" }} onClick={() => prev()}>
                  Previous
                </Button>
              )}
            </div>
          </Form>
        </Content>
      </Layout>
    </>
  );
};

export default ObservationPage;
