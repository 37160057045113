import { List, Layout, Card } from "antd";
import { AppstoreAddOutlined } from "@ant-design/icons";
import { NavLink } from "react-router-dom";

import AppPageHeader from "./AppPageHeader";

const { Content } = Layout;

// current app list.
const appList = [
  {
    title: "SafeCard",
    link: "/home",
  },
];

export default function AppPage() {
  return (
    <Layout className="layout">
      <AppPageHeader />
      <Content style={{ padding: "20px" }}>
        <List
          grid={{ gutter: 16, column: 1 }}
          dataSource={appList}
          renderItem={(item) => (
            <NavLink to={item.link}>
              <List.Item style={{ textAlign: "center" }}>
                <Card>
                  <AppstoreAddOutlined />
                  <b> SAFE CARD</b>
                </Card>
              </List.Item>
            </NavLink>
          )}
        />
      </Content>
    </Layout>
  );
}
