import React from "react";
import { Switch, Redirect, useLocation } from "react-router-dom";
// import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnAuthenticatedRoute";

import AppPage from "./pages/app/AppPage";
import DetailsPage from "./pages/home/DetailsPage";
import HomePage from "./pages/home/HomePage";
import NewChecklistPage from "./pages/safe-card/ObservationPage";

//TODO: Clean up routes
export default function Routes({ appProps }) {
  const location = useLocation();
  return (
    <Switch location={location} key={location.pathname}>
      <Redirect exact from="/" to="/app" />
      <UnauthenticatedRoute
        exact
        appProps={appProps}
        path="/app"
        component={AppPage}
      ></UnauthenticatedRoute>
      <UnauthenticatedRoute
        exact
        appProps={appProps}
        path="/home"
        component={HomePage}
      ></UnauthenticatedRoute>
      <UnauthenticatedRoute
        exact
        appProps={appProps}
        path="/new-checklist"
        component={NewChecklistPage}
      ></UnauthenticatedRoute>
      <UnauthenticatedRoute
        exact
        appProps={appProps}
        path="/details/:id"
        component={DetailsPage}
      ></UnauthenticatedRoute>
    </Switch>
  );
}
