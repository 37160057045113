import "./App.less";
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { AppContext } from "./libs/contextLib";
import Routes from "./Routes";
import { Auth, Hub, Cache } from "aws-amplify";

function App() {
  //Initialize global state
  // const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, userHasAuthenticated] = useState(false);

  // store user state for the app
  const [user, setUser] = useState(null);

  useEffect(() => {
    // listen to auth events from amplify at startup
    Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
        case "cognitoHostedUI":
          getUser().then((userData) => setUser(userData)); // Save user data to state
          break;
        case "signOut":
          setUser(null); // Remove user from the state
          Cache.clear(); // Remove cached data from the previous user
          break;
        case "signIn_failure":
        case "cognitoHostedUI_failure":
          console.log("Sign in failure", data);
          break;
      }
    });

    getUser().then((userData) => setUser(userData));
  }, []);

  // Save user attributes to cache to be used later when adding a safe card
  useEffect(() => {
    if (user) {
      Cache.setItem(
        "name",
        `${user.attributes.given_name} ${user.attributes.family_name}`
      );
      Cache.setItem("email", user.attributes.email);
      Cache.setItem("department", user.attributes["custom:Department"]);
      Cache.setItem(
        "admin",
        user.signInUserSession.idToken.payload["cognito:groups"].includes(
          "Admin"
        )
      );
    }
  }, [user]);

  function getUser() {
    return Auth.currentAuthenticatedUser()
      .then((userData) => userData)
      .catch(() => federateSignIn());
  }

  function federateSignIn() {
    Auth.federatedSignIn();
  }

  return (
    <AppContext.Provider>
      <div>
        <Routes
          appProps={{
            isAuthenticated,
            userHasAuthenticated,
          }}
        />
      </div>
    </AppContext.Provider>
  );
}

export default withRouter(App);
