import {
  Input,
  Typography,
  Radio,
  Select,
  DatePicker,
  Row,
  Col,
  message,
  Upload,
  Form,
} from "antd";
import { useState, useEffect } from "react";
import moment from "moment";
import fields from "./fields.json";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Storage, Cache, Hub } from "aws-amplify";
import UserListComponent from "./UserListComponent";

const { TextArea } = Input;
const { Text } = Typography;

function SubmissionPage(props) {
  const [loading, setLoading] = useState(false);

  const {
    furtherAction,
    setFurtherAction,
    location,
    setLocation,
    department,
    setDepartment,
    safeActs,
    setSafeActs,
    unsafeActs,
    setUnsafeActs,
    setOtherLocation,
    status,
    images,
    setImages,
    setObservedBy,
  } = props;

  const onChange = (e) => {
    setFurtherAction(e.target.value);
  };

  const onChangeSafeActs = (e) => {
    setSafeActs(e.target.value);
  };

  const onChangeUnsafeActs = (e) => {
    setUnsafeActs(e.target.value);
  };

  const onChangeLocation = (e) => {
    setOtherLocation(e.target.value);
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  function beforeUpload(file) {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 10;
    if (!isLt2M) {
      message.error("Image must smaller than 10MB!");
    }
    return isJpgOrPng && isLt2M;
  }

  function handleChange(info) {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      setLoading(false);
    }
  }

  function generateFileName() {
    return `${Cache.getItem("department")}/${moment()
      .format("LL")
      .toString()}/${Math.floor(Math.random() * 100000)}.png`;
  }

  async function customRequest(e) {
    const file = e.file;

    const fileKey = generateFileName();

    setImages([...images, fileKey]);

    Storage.put(fileKey, file, {
      contentType: "image/png",
    })
      .then((result) => {
        setLoading(false);
        console.log(result);
        e.onSuccess(result);
      })
      .catch((err) => e.onError(err));
  }

  const { Option } = Select;

  return (
    <div>
      {!status.includes("error") && (
        <Row style={{ marginBottom: 10 }}>
          <Text>
            Safe actions observed. Actions observed to encourage continued safe
            performance.
          </Text>

          <TextArea
            style={{ padding: "0 20px" }}
            rows={4}
            onChange={onChangeSafeActs}
            value={safeActs}
          />
        </Row>
      )}
      {status.includes("error") && (
        <Row style={{ marginBottom: 10 }}>
          <Text>
            Unsafe act or conditions observed. Immediate correction action taken
            to prevent recurrence
          </Text>

          <TextArea
            style={{ padding: "0 20px" }}
            rows={4}
            onChange={onChangeUnsafeActs}
            value={unsafeActs}
          />
        </Row>
      )}
      <Row gutter={[16, 16]} style={{ textAlign: "start", marginBottom: 10 }}>
        <Col span={12}>
          <Text>Further Action Required</Text>
        </Col>
        <Col span={12}>
          <Radio.Group onChange={onChange} value={furtherAction}>
            <Radio value={false}>No</Radio>
            <Radio value={true}>Yes</Radio>
          </Radio.Group>
        </Col>
      </Row>
      {navigator.onLine && (
        <Row gutter={[16, 16]} style={{ textAlign: "start", marginBottom: 10 }}>
          <Col span={12}>
            <Text>Camera</Text>
          </Col>
          <Col span={12}>
            <Upload
              name="avatar"
              listType="picture-card"
              className="avatar-uploader"
              accept="image/*"
              capture="camera"
              showUploadList={true}
              beforeUpload={beforeUpload}
              onChange={handleChange}
              customRequest={customRequest}
            >
              {uploadButton}
            </Upload>
          </Col>
        </Row>
      )}
      <Row gutter={[16, 16]} style={{ textAlign: "start", marginBottom: 10 }}>
        <Col span={12}>
          <Text>Location</Text>
        </Col>
        <Col span={12}>
          <Select
            value={location}
            style={{ width: 140 }}
            onChange={setLocation}
          >
            {fields.location.map((location, index) => (
              <Option key={index} value={location}>
                {location}
              </Option>
            ))}
          </Select>
        </Col>
      </Row>
      {location === "Other" && (
        <Row gutter={[16, 16]} justify="end">
          <Col span={12}>
            <Form.Item
              name="location"
              rules={[
                {
                  required: true,
                  message: "Please enter a location.",
                },
              ]}
            >
              <Input
                placeholder="Location"
                style={{ width: 140 }}
                onChange={onChangeLocation}
              />
            </Form.Item>
          </Col>
        </Row>
      )}
      {Cache.getItem("admin") && (
        <UserListComponent
          setDepartment={setDepartment}
          setObservedBy={setObservedBy}
        />
      )}

      <Row gutter={[16, 16]} style={{ textAlign: "start", marginBottom: 10 }}>
        <Col span={12}>
          <Text>Business Unit</Text>
        </Col>
        <Col span={12}>
          <Select
            value={department}
            style={{ width: 140 }}
            onChange={setDepartment}
            // for admin access to TES TTC department, enable department selection
            disabled
          >
            {fields.department.map((department, index) => (
              <Option key={index} value={department}>
                {department}
              </Option>
            ))}
          </Select>
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ textAlign: "start", marginBottom: 10 }}>
        <Col span={12}>
          <Text>Date</Text>
        </Col>
        <Col span={12}>
          <DatePicker
            style={{ width: 140 }}
            defaultValue={moment()}
            // for admin access to TES TTC department, enable datepicker
            disabled={!Cache.getItem("admin")}
          />
        </Col>
      </Row>
    </div>
  );
}

export default SubmissionPage;
