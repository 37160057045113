import { useEffect, useState } from "react";
import { Descriptions, Layout, Typography, Space } from "antd";
import { useParams } from "react-router-dom";
import Header from "../../components/Header";
import { useHistory } from "react-router-dom";
import S3ImageComponent from "./S3ImageComponent";
import { RightSquareOutlined } from "@ant-design/icons";
import { SafeCard } from "../../models";
import { DataStore } from "@aws-amplify/datastore";
import { Hub } from "@aws-amplify/core";

const { Content, Footer } = Layout;
const { Text } = Typography;

function DetailsPage() {
  let history = useHistory();
  let { id } = useParams();
  const [safeCard, setSafeCard] = useState();

  useEffect(() => {
    fetchSafeCard(id);
    // Create listener that will stop observing the model once the sync process is done
    const removeListener = Hub.listen("datastore", async (capsule) => {
      const {
        payload: { event, data },
      } = capsule;

      console.log("DataStore event", event, data);

      if (event === "ready") {
        fetchSafeCard(id);
      }
    });

    // Start the DataStore, this kicks-off the sync process.
    DataStore.start();

    return () => {
      removeListener();
    };
  }, []);

  async function fetchSafeCard() {
    try {
      const safeCard = await DataStore.query(SafeCard, id);
      setSafeCard(safeCard);
    } catch (err) {
      console.log(err);
    }
  }

  function renderEquipment() {
    if (safeCard.equipment && safeCard.equipment.length)
      return (
        <div>
          <Text strong>Personal Protective Equipment</Text>
          {safeCard.equipment.map((item) => (
            <div>
              <RightSquareOutlined />
              <Text key={item.id}>{item}</Text>
            </div>
          ))}
        </div>
      );
  }
  function renderReactions() {
    if (safeCard.reactions && safeCard.reactions.length)
      return (
        <div>
          <Text strong>Reactions of People</Text>
          {safeCard.reactions.map((item) => (
            <div>
              <RightSquareOutlined />
              <Text key={item.id}>{item}</Text>
            </div>
          ))}
        </div>
      );
  }
  function renderPositions() {
    if (safeCard.positions && safeCard.positions.length)
      return (
        <div>
          <Text strong>Positions Of People (Injury Causes)</Text>
          {safeCard.positions.map((item) => (
            <div>
              <RightSquareOutlined />
              <Text key={item.id}>{item}</Text>
            </div>
          ))}
        </div>
      );
  }
  function renderTools() {
    if (safeCard.tools && safeCard.tools.length)
      return (
        <div>
          <Text strong>Tools and Equipment</Text>
          {safeCard.tools.map((item) => (
            <div>
              <RightSquareOutlined />
              <Text key={item.id}>{item}</Text>
            </div>
          ))}
        </div>
      );
  }
  function renderProcedures() {
    if (safeCard.procedures && safeCard.procedures.length)
      return (
        <div>
          <Text strong>Safe Work Procedures</Text>
          {safeCard.procedures.map((item) => (
            <div>
              <RightSquareOutlined />
              <Text key={item.id}>{item}</Text>
            </div>
          ))}
        </div>
      );
  }
  return (
    <Layout className="layout">
      <Header history={history} />
      <Content style={{ padding: "20px 20px" }}>
        {safeCard !== undefined && (
          <div>
            <Descriptions
              title="Safety Report"
              bordered
              column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
            >
              <Descriptions.Item label="Date" key="Date">
                {safeCard.date}
              </Descriptions.Item>
              <Descriptions.Item label="Business Unit" key="Business Unit">
                {safeCard.department}
              </Descriptions.Item>
              <Descriptions.Item
                label="Further Action Required"
                key="Further Action Required"
              >
                {safeCard.furtherAction ? "Yes" : "No"}
              </Descriptions.Item>
              <Descriptions.Item label="Location" key="Location">
                {safeCard.location}
              </Descriptions.Item>
              <Descriptions.Item label="Observed By" key="Observed By">
                {safeCard.observedBy}
              </Descriptions.Item>
              <Descriptions.Item
                label="Observation Checklist"
                key="Observation Checklist"
              >
                <Space direction="vertical">
                  {renderReactions()}
                  {renderEquipment()}
                  {renderPositions()}
                  {renderTools()}
                  {renderProcedures()}
                </Space>
              </Descriptions.Item>
              <Descriptions.Item
                label="Safe Acts Observed"
                key="Safe Acts Observed"
              >
                {safeCard.safeActs}
              </Descriptions.Item>
              <Descriptions.Item
                label="Unsafe Acts Observed"
                key="Unsafe Acts Observed"
              >
                {safeCard.unsafeActs}
              </Descriptions.Item>
            </Descriptions>
            <Descriptions
              bordered
              column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
            >
              {safeCard.images.map((image, index) => (
                <Descriptions.Item label={`Photo ${index + 1}`} key={index}>
                  <S3ImageComponent image={image} />
                </Descriptions.Item>
              ))}
            </Descriptions>
          </div>
        )}
      </Content>
      <Footer />
    </Layout>
  );
}

export default DetailsPage;
