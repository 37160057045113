import { Layout, Row } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import logo from "../assets/images/tucker_logo.png";

const { Header } = Layout;

export default function CustomHeader(props) {
  const { history } = props;
  return (
    <Header style={{ padding: "0 20px" }}>
      <Row>
        <div onClick={() => history.goBack()}>
          <LeftOutlined />
        </div>
        <div style={{ flex: 1, textAlign: "center", paddingRight: 20 }}>
          <img
            src={logo}
            style={{
              width: "100px",
              height: "30px",
            }}
          />
        </div>
      </Row>
    </Header>
  );
}
