import { useState, useEffect } from "react";
import { Storage } from "aws-amplify";
import { Image } from "antd";

function S3ImageComponent(props) {
  const [url, setUrl] = useState("");

  useEffect(() => {
    const { image } = props;
    getPresignedUrl(image);
  }, []);

  async function getPresignedUrl(image) {
    const url = await Storage.get(image);
    setUrl(url);
  }

  return <Image width={200} src={url} />;
}

export default S3ImageComponent;
